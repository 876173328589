@import '../../styles/mixins';

.audioPlayer {
  position: fixed;
  bottom: 18px;
  left: 44px;
  z-index: 1;

  @include mq(md) {
    display: none;
  }
}

.soundSwitch {
  position: fixed;
  top: 20px;
  right: 24px;
  z-index: 1;

  @include mq(sm) {
    top: 16px;
    right: 16px;
  }
}

.pageMap {
  position: fixed;
  right: 32px;
  bottom: 20px;
  z-index: 1;

  @include mq(sm) {
    right: 24px;
    bottom: 16px;
  }
}

.snsOuter {
  position: fixed;
  right: 120px;
  bottom: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 48px;

  @include mq(md) {
    bottom: 24px;
    left: 24px;
    width: 48px;
    height: auto;
  }
}
