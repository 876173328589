@import '../../styles/variables';
@import '../../styles/mixins';

.pageContent {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include mq(md) {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    max-width: calc(100% - #{$windowWidth} / 13.2 - 32px);
    padding: $pageVerticalPadding $pageHorizontalPadding $pageVerticalPadding
      32px;
    box-sizing: border-box;
    background: $colorContentBackground;
    overflow-y: auto;
  }

  @include mq(sm) {
    padding: $pageVerticalPaddingSm $pageHorizontalPaddingSm
      $pageVerticalPaddingSm 32px;
  }
}

.title {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: $windowHeight;
  padding: 8px $pageVerticalPadding;
  box-sizing: border-box;
  font-size: 4rem;
  letter-spacing: 0.16rem;
  background: $colorContentBackground;
  transform-origin: 0 0;
  transform: translateY(100%) rotate(-90deg);

  @include mq(md) {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    margin-top: 40px;
    padding: 0;
    transform: translateY(0) rotate(0);
    background: none;
  }

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 10px;
    background: $colorContentBackground;
    content: '';

    @include mq(md) {
      display: none;
    }
  }
}

.titleImg {
  width: auto;
  height: 36px;
  padding: 4px 16px 0;

  @include mq(md) {
    padding: 0;
  }

  &._contact {
    height: 28px;
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  padding: $pageVerticalPadding $pageHorizontalPadding $pageVerticalPadding 32px;
  box-sizing: border-box;
  background: $colorContentBackground;
  overflow-y: auto;

  @include mq(md) {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    background: none;
  }
}
