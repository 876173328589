@import '../../styles/mixins';

$heightRatio: var(--height-ratio, 1);

$clipOutsideStroke: 1.6;
$clipInsideStroke: 0.6;

$clipOutsideMargin: 1.2;
$clipInsideMargin: 4.2;

$clipOutsideRectTopLeft: $clipOutsideMargin;
$clipOutsideRectRightBottom: 100 - $clipOutsideMargin;

$clipInsideRectTopLeft: $clipOutsideMargin + $clipOutsideStroke +
  $clipInsideMargin;
$clipInsideRectRightBottom: 100 - $clipOutsideMargin - $clipOutsideStroke -
  $clipInsideMargin;

@function positionY($percent) {
  @if $percent < 50 {
    @return calc(#{$percent + '%'} * #{$heightRatio});
  }

  $movement: 100 - $percent;
  @return calc(100% - (#{$movement + '%'} * #{$heightRatio}));
}

@mixin makeClipPath($scale: 1) {
  $clipOutsideStroke: $clipOutsideStroke * $scale;
  $clipInsideStroke: $clipInsideStroke * $scale;

  $clipOutsideMargin: $clipOutsideMargin * $scale;
  $clipInsideMargin: $clipInsideMargin * $scale;

  $clipOutsideRectTopLeft: $clipOutsideMargin;
  $clipOutsideRectRightBottom: 100 - $clipOutsideMargin;

  $clipInsideRectTopLeft: $clipOutsideMargin + $clipOutsideStroke +
    $clipInsideMargin;
  $clipInsideRectRightBottom: 100 - $clipOutsideMargin - $clipOutsideStroke -
    $clipInsideMargin;

  clip-path: polygon(
    #{$clipOutsideRectTopLeft + '%'} positionY($clipOutsideRectTopLeft),
    #{$clipOutsideRectTopLeft + '%'} positionY($clipOutsideRectRightBottom),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipOutsideRectRightBottom),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipOutsideRectTopLeft +
          $clipOutsideStroke),
    #{$clipOutsideRectRightBottom - $clipOutsideStroke + '%'} positionY($clipOutsideRectTopLeft +
          $clipOutsideStroke),
    #{$clipOutsideRectRightBottom - $clipOutsideStroke + '%'} positionY($clipOutsideRectRightBottom -
          $clipOutsideStroke),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipOutsideRectRightBottom -
          $clipOutsideStroke),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipInsideRectRightBottom),
    #{$clipInsideRectRightBottom + '%'} positionY($clipInsideRectRightBottom),
    #{$clipInsideRectRightBottom + '%'} positionY($clipInsideRectTopLeft),
    #{$clipInsideRectTopLeft + '%'} positionY($clipInsideRectTopLeft),
    #{$clipInsideRectTopLeft + '%'} positionY($clipInsideRectRightBottom -
          $clipInsideStroke),
    #{$clipInsideRectTopLeft + $clipInsideStroke + '%'} positionY($clipInsideRectRightBottom -
          $clipInsideStroke),
    #{$clipInsideRectTopLeft + $clipInsideStroke + '%'} positionY($clipInsideRectTopLeft +
          $clipInsideStroke),
    #{$clipInsideRectRightBottom - $clipInsideStroke + '%'} positionY($clipInsideRectTopLeft +
          $clipInsideStroke),
    #{$clipInsideRectRightBottom - $clipInsideStroke + '%'} positionY($clipInsideRectRightBottom -
          $clipInsideStroke),
    #{$clipInsideRectTopLeft + '%'} positionY($clipInsideRectRightBottom -
          $clipInsideStroke),
    #{$clipInsideRectTopLeft + '%'} positionY($clipInsideRectRightBottom),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipInsideRectRightBottom),
    #{$clipOutsideRectTopLeft + $clipOutsideStroke + '%'} positionY($clipOutsideRectRightBottom),
    #{$clipOutsideRectRightBottom + '%'} positionY($clipOutsideRectRightBottom),
    #{$clipOutsideRectRightBottom + '%'} positionY($clipOutsideRectTopLeft)
  );
}

.scaleBackgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  @include makeClipPath();

  @include mq(sm) {
    @include makeClipPath(1.4);
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &._active {
    animation: scaleImg 8s infinite cubic-bezier(0.29, 0.01, 0, 0.99) alternate;
  }
}

@keyframes scaleImg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(2);
  }
}
