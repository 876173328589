$translateZ: 6000px;
$perspective: 1000px;
$rotate: 50deg;
$distance: 110%;
$easing: cubic-bezier(0.42, 0.01, 0.07, 0.99);

.slide {
  overflow: hidden;

  &-top-enter,
  &-right-enter,
  &-bottom-enter,
  &-left-enter {
    position: relative;
    z-index: 1;
    opacity: 0;
    transform-origin: center center 2000px;

    // 画面遷移中にリンクを押せないようにしている
    a[class^='Link_link'] {
      pointer-events: none;
    }
  }

  &-top-enter-active,
  &-right-enter-active,
  &-bottom-enter-active,
  &-left-enter-active {
    z-index: 1;
    opacity: 1;
  }

  &-top-enter,
  &-right-enter,
  &-bottom-enter,
  &-left-enter,
  &-top-exit,
  &-right-exit,
  &-bottom-exit,
  &-left-exit {
    will-change: transform, opacity;
    transition: transform 800ms $easing, opacity 800ms $easing;
  }

  &-exit,
  &-top-exit,
  &-right-exit,
  &-bottom-exit,
  &-left-exit {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: translateZ(0);
  }
}

// 上への遷移
.slide-top-enter {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateX(230deg) scale(1, -1);
}

.slide-top-enter-active {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateX(180deg) scale(1, -1);
}

// 右への遷移
.slide-right-enter {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateY(230deg) scale(-1, 1);
}

.slide-right-enter-active {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateY(180deg) scale(-1, 1);
}

// 下への遷移
.slide-bottom-enter {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateX(130deg) scale(1, -1);
}

.slide-bottom-enter-active {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateX(180deg) scale(1, -1);
}

// 左への遷移
.slide-left-enter {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateY(130deg) scale(-1, 1);
}

.slide-left-enter-active {
  transform: perspective($perspective) translate3d(0, 0, -2000px)
    rotateY(180deg) scale(-1, 1);
}
