.soundSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  padding: 8px;
  letter-spacing: 1px;
  cursor: pointer;

  &:first-of-type {
    width: 40px;
  }

  &:last-of-type {
    width: 48px;
  }

  &._active {
    font-weight: 700;
  }

  &:not(._active) {
    opacity: 0.8;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.9;
    }
  }
}

.separator {
  font-size: 2.8rem;
}
