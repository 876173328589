@import '../../styles/mixins';

.titleImg {
  width: 300px;
  height: auto;

  @include mq(sm) {
    width: 160px;
  }
}
