@import '../../styles/variables';
@import '../../styles/mixins';

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-y: auto;

  @include mq(md) {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

.contentInner {
  position: relative;
  width: 640px;
  max-width: calc(
    #{$windowWidth} / 2 - #{$windowHeight} * #{$heightRatio} / 13.2
  );
  min-height: 100%;
  padding: $pageVerticalPadding 32px;
  box-sizing: border-box;
  background: $colorContentBackground;

  @include mq(md) {
    width: auto;
    max-width: 100%;
    min-height: 100%;
    padding: 0 32px 0 0;
    background: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: -80px;
    width: 40px;
    height: 100%;
    background: $colorContentBackground;
    content: '';
  }
}

.profileImg {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-top: 32px;
}

.nameGroup {
  margin-top: 30px;
}

.name {
  line-height: 1.1;
  font-size: 4rem;
}

.nameSub {
  display: inline-block;
  line-height: 1.1;
  margin-top: 4px;
  font-size: 1.6rem;
}

.profileText {
  line-height: 1.8;
  margin: 32px 0;
  font-size: 1.3rem;
}

.signatureImgOuter {
  display: block;
  width: 148px;
  margin-left: 72px;

  @include mq(md) {
    margin-bottom: $pageVerticalPadding;
  }

  @media screen and (max-width: 400px) {
    margin-left: 32px;
  }

  @include mq(xs) {
    margin-left: 0;
  }
}

.signatureImg {
  width: 100%;
  margin-bottom: 64px;
  text-align: right;
}
