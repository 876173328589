@import '../../styles/mixins';

.list {
  display: flex;
  gap: 20px;

  @include mq(md) {
    flex-direction: column;
  }
}

.listItem {
}

.link {
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.95;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
