.IEMessageOuter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: white;
  color: black;
}

.IEMessage {
  line-height: 1.8;
  margin-bottom: 32px;
  font-size: 1.8rem;
}

.IEMessageLink {
  display: block;
  margin-top: 8px;
  font-size: 1.6rem;
  text-decoration: underline;
}
