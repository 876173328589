@import '../../styles/variables';

.pageContainer {
  width: $windowWidth;
  height: $windowHeight;
  background: black;
  overflow: hidden;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.videoBackgroundImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  position: fixed;

  &.top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
