@font-face {
  font-family: 'Noto Serif JP', serif;
  font-weight: 400;
  src: local('NotoSerifJP-Regular');
}

@font-face {
  font-family: 'Noto Serif JP', serif;
  font-weight: 700;
  src: local('NotoSerifJP-Bold');
}

html {
  height: 100%;
  font-size: 62.5%;
  font-family: 'Noto Serif JP', 'Times New Roman', 'YuMincho',
    'Hiragino Mincho ProN', 'Yu Mincho', 'MS PMincho', serif;
  color: white;
}

body {
  height: 100%;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input,
textarea {
  font-family: inherit;

  &::placeholder {
    font-family: inherit;
  }
}

#root {
  overflow: hidden;
}
