@import '../../styles/variables';

.form {
  width: 600px;
  max-width: calc(100% - 32px);
  margin: 16px 0 $pageVerticalPadding 0;
  padding: 32px 0;
}

.label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 4px;
}

.input {
  width: 100%;
  height: 48px;
  padding-left: 12px;
  margin-bottom: 46px;
  font-size: 1.6rem;
  box-sizing: border-box;
}

.textarea {
  width: 100%;
  height: 240px;
  padding: 12px 16px;
  margin-bottom: 46px;
  font-size: 1.6rem;
  box-sizing: border-box;
}

.required {
  color: #fcee21;
}

.submit {
  display: block;
  margin: 0 auto;
  width: 240px;
  max-width: 100%;
  height: 48px;
  background-color: rgba(#000, 0.6);
  border: solid 1px white;
  color: white;
  cursor: pointer;
  transition: opacity 200ms;

  &:hover {
    opacity: 0.8;
  }
}
