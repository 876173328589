$varWindowWidth: '--window-width';
$varWindowHeight: '--window-height';
$varHeightRatio: '--height-ratio';

$windowWidth: var(#{$varWindowWidth}, 100vw);
$windowHeight: var(#{$varWindowHeight}, 100vh);
$heightRatio: var(--height-ratio, 1);

$pageHorizontalPadding: calc(#{$windowWidth} / 13.2);
$pageHorizontalPaddingSm: calc(#{$windowWidth} / 9.4);

$pageVerticalPadding: calc(#{$windowHeight} * #{$heightRatio} / 13.2);
$pageVerticalPaddingSm: calc(#{$windowHeight} * #{$heightRatio} / 9.4);

$colorContentBackground: rgba(black, 0.7);

$breakpoints: (
  'xs': 'screen and (max-width: 349px)',
  'sm': 'screen and (max-width: 559px)',
  'md': 'screen and (max-width: 959px)',
) !default;
