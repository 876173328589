.row {
  display: flex;
}

.cell {
  position: relative;
  width: 12px;
  height: 12px;
  margin: 2px;
  box-sizing: border-box;
  border: 1px solid white;

  &._current {
    background: white;
  }

  &._hidden {
    visibility: hidden;
  }
}

.pipe {
  $length: 6px;
  position: absolute;
  background: rgba(white, 0.5);

  &._top {
    top: #{-$length};
    left: 0;
    right: 0;
    width: 1px;
    height: $length;
    margin: 0 auto;
  }

  &._right {
    top: 0;
    bottom: 0;
    right: #{-$length};
    width: $length;
    height: 1px;
    margin: auto 0;
  }

  &._bottom {
    left: 0;
    right: 0;
    bottom: #{-$length};
    width: 1px;
    height: $length;
    margin: 0 auto;
  }

  &._left {
    top: 0;
    bottom: 0;
    left: #{-$length};
    width: $length;
    height: 1px;
    margin: auto 0;
  }
}
