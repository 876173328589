@import '../../styles/mixins';

$easing: cubic-bezier(0.5, 1, 0.89, 1);
$duration: 2s;

@keyframes extendTop {
  0% {
    transform: scaleY(1) translateY(-50%);
  }

  10% {
    transform: scaleY(24) translateY(-50%);
  }

  40% {
    transform: scaleY(24) translateY(-50%);
  }

  65% {
    transform: scaleY(24) translateY(-200%);
  }

  100% {
    transform: scaleY(24) translateY(-200%);
  }
}

@keyframes extendRight {
  0% {
    transform: scaleX(1) translateX(50%);
  }

  10% {
    transform: scaleX(24) translateX(50%);
  }

  40% {
    transform: scaleX(24) translateX(50%);
  }

  65% {
    transform: scaleX(24) translateX(200%);
  }

  100% {
    transform: scaleX(24) translateX(200%);
  }
}

@keyframes extendBottom {
  0% {
    transform: scaleY(1) translateY(50%);
  }

  10% {
    transform: scaleY(24) translateY(50%);
  }

  40% {
    transform: scaleY(24) translateY(50%);
  }

  65% {
    transform: scaleY(24) translateY(200%);
  }

  100% {
    transform: scaleY(24) translateY(200%);
  }
}

@keyframes extendLeft {
  0% {
    transform: scaleX(1) translateX(-50%);
  }

  10% {
    transform: scaleX(24) translateX(-50%);
  }

  40% {
    transform: scaleX(24) translateX(-50%);
  }

  65% {
    transform: scaleX(24) translateX(-200%);
  }

  100% {
    transform: scaleX(24) translateX(-200%);
  }
}

.link {
  position: relative;
  display: inline-block;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
  }

  &::before {
    background: white;
  }

  &::after {
    width: 1px;
    height: 1px;
    background: white;
  }

  &._top {
    padding: calc(#{$windowHeight} * #{$heightRatio} / 30) 16px 16px;

    &::before {
      width: 1px;
      height: 2px;
      top: calc(#{$windowHeight} * #{$heightRatio} / 50);
      right: 0;
      left: 0;
      margin: 0 auto;
    }

    &::after {
      top: calc(#{$windowHeight} * #{$heightRatio} / 50 - 3px);
      right: 0;
      left: 0;
      margin: 0 auto;
      animation: extendTop $duration infinite $easing;
    }
  }

  &._right {
    padding: 16px 3.3vw 16px 16px;

    &::before {
      width: 2px;
      height: 1px;
      top: 0;
      right: 2vw;
      bottom: 0;
      margin: auto 0;
    }

    &::after {
      top: 0;
      right: 1.7vw;
      bottom: 0;
      margin: auto 0;
      animation: extendRight $duration infinite $easing;
    }
  }

  &._bottom {
    padding: 16px 16px calc(#{$windowHeight} * #{$heightRatio} / 30);

    &::before {
      width: 1px;
      height: 2px;
      right: 0;
      bottom: calc(#{$windowHeight} * #{$heightRatio} / 50);
      left: 0;
      margin: 0 auto;
    }

    &::after {
      right: 0;
      bottom: calc(#{$windowHeight} * #{$heightRatio} / 50 - 3px);
      left: 0;
      margin: 0 auto;
      animation: extendBottom $duration infinite $easing;
    }
  }

  &._left {
    padding: 16px 16px 16px 3.3vw;

    &::before {
      width: 2px;
      height: 1px;
      top: 0;
      bottom: 0;
      left: 2vw;
      margin: auto 0;
    }

    &::after {
      top: 0;
      bottom: 0;
      left: 1.7vw;
      margin: auto 0;
      animation: extendLeft $duration infinite $easing;
    }
  }
}

.text {
  display: inline-block;
  font-size: 1.8rem;
  text-shadow: 0 0 4px rgba(black, 1);
  white-space: nowrap;

  @include mq(md) {
    .link._top > & {
      position: absolute;
      top: 28px;
      transform: translateX(-50%);
    }

    .link._right > & {
      position: absolute;
      right: 48px;
      top: 0;
      bottom: 0;
      transform: translateX(50%) rotate(90deg);
    }

    .link._bottom > & {
      position: absolute;
      bottom: 28px;
      transform: translateX(-50%);
    }

    .link._left > & {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 48px;
      transform: translateX(-50%) rotate(-90deg);
    }
  }

  @include mq(sm) {
    .link._top > & {
      top: 20px;
    }

    .link._right > & {
      right: 36px;
    }

    .link._bottom > & {
      bottom: 20px;
    }

    .link._left > & {
      left: 36px;
    }
  }
}
