@import '../../styles/mixins';

@keyframes blackOut {
  0% {
    background-color: white;
  }

  25% {
    background-color: white;
  }

  100% {
    background-color: black;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    display: flex;
    opacity: 1;
  }

  99.9% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.landing {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
  color: black;

  &._loading {
    animation: blackOut 4s cubic-bezier(0.55, 0, 1, 0.45) forwards;
  }

  &._active {
    display: flex;
    opacity: 1;
  }

  &:not(._active) {
    background: black;
    pointer-events: none;
    animation: fadeOut 1s ease forwards;
  }
}

.switchOuter {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeIn 300ms ease forwards 1s;

  &:not(._active) {
    display: none;
  }
}

.switch {
  padding: 20px 36px;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 200ms;

  @include mq(sm) {
    font-size: 2.4rem;
    padding: 16px 8px;
  }

  &:first-of-type {
    align-self: flex-start;
  }

  &:last-of-type {
    align-self: flex-end;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.6;
  }
}

.separator {
  width: 2px;
  height: 180px;
  background: black;
  transform: rotate(28deg);

  @include mq(sm) {
    height: 128px;
  }
}

.countOuter {
  &:not(._active) {
    display: none;
  }
}

.count {
  display: inline-block;
  width: 100px;
  font-size: 6.4rem;
  font-weight: 500;
  font-feature-settings: 'palt';
  text-align: center;
  letter-spacing: 0.1rem;
}

.percent {
  margin-left: -10px;
  font-size: 4.2rem;
  font-weight: 700;
}
