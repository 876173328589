@import '../../styles/mixins';

.discoList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 32px 0 0;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;

  @include mq(sm) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.title {
  display: block;
  font-size: 3.6rem;
  margin-left: 16px;

  @include mq(sm) {
    margin-left: 0;
  }
}

.subTitle {
  display: block;
  line-height: 1.3;
  margin: 8px 0 0 20px;
  font-size: 1.6rem;

  @include mq(sm) {
    margin-left: 2px;
    margin-bottom: 24px;
  }
}

.img {
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.list {
  padding: 48px 16px 48px 0;
  box-sizing: border-box;
}

.listItem {
  & + & {
    margin-top: 48px;
  }
}

.trackTitle {
  display: block;
  line-height: 1.5;
  font-size: 2.4rem;
  white-space: nowrap;

  @include mq(sm) {
    font-size: 1.8rem;
  }

  @include mq(xs) {
    white-space: pre-line;
  }
}

.text {
  line-height: 1.5;
  font-size: 1.4rem;
  margin-top: 4px;

  @include mq(sm) {
    display: block;
    font-size: 1.3rem;
  }

  & + & {
    &::before {
      margin: 0 6px;
      font-size: 1.8rem;
      content: '/';

      @include mq(sm) {
        display: none;
      }
    }
  }
}

.linkList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin: 24px 0 $pageVerticalPadding;

  @include mq(md) {
    padding-bottom: 72px;
  }

  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 8px;
  }

  & + & {
    margin-top: 8px;
  }
}

.linkItem {
  flex: 0 1 130px;
  width: 100%;
  min-width: 100px;
  height: 32px;

  @include mq(sm) {
    flex: 0 1 auto;
    width: 120px;
    height: 32px;
  }
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.95;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @include mq(sm) {
      object-position: left center;
    }
  }
}

.linkImg {
  width: auto;
  height: 24px;
  object-fit: cover;
}
