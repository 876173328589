@import '../../styles/variables';
@import '../../styles/mixins';

.emptyRoom {
  position: relative;
  width: $windowWidth;
  height: $windowHeight;
  background: black;
  overflow: hidden;
}

.imgOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &._sm {
    display: none;
  }

  @include mq(sm) {
    display: none;

    &._sm {
      display: block;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
