.audioPlayer {
  display: flex;
  align-items: center;
  width: 274px;
}

.title {
  margin-top: 3px;
  font-size: 1.4rem;
  white-space: nowrap;
}

.rangeOuter {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 5px;
    margin: auto 0;
    background: white;
    content: '';
  }

  &::before {
    left: 3px;
  }

  &::after {
    right: 3px;
  }
}

.range {
  height: 1px;
  width: 100%;
  margin: 0 3px;
  background-color: rgba(white, 0.7);
  appearance: none;

  &:focus,
  &:active {
    outline: none;
  }

  &::-webkit-slider-thumb {
    position: relative;
    display: block;
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 50%;
    appearance: none;
  }
}
